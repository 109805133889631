import PropTypes from "prop-types";
import React from "react";

const Brands = (props) => (
    <section style={{ backgroundColor: `` }}>
        <div className="w-full max-w-4xl mx-auto px-4 py-8 md:px-8 md:py-16">
            <h2 className="text-xl mb-6 md:mb-10 text-center leading-tight font-display max-w-xl mx-auto">
                {props.title}
            </h2>

            <div className="flex flex-wrap justify-around items-center">
                {props.content.map((item, i) => (
                    <img
                        key={i}
                        src={`../images/featured-shops/${i + 1}.png`}
                        alt=""
                        style={{
                            width:
                                item.width !== null
                                    ? parseInt(item.width)
                                    : `auto`,
                            height:
                                item.height !== null
                                    ? parseInt(item.height)
                                    : `auto`,
                        }}
                        className="content-center w-32 md:w-44 flex mx-4 mb-6"
                    />
                ))}
            </div>
        </div>
    </section>
);

Brands.propTypes = {
    props: PropTypes.array,
    title: PropTypes.string,
    content: PropTypes.array,
};

export default Brands;
