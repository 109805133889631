import PropTypes from "prop-types";
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ReactComponent as Market1 } from "../images/selling-on-social-media.svg";
import Market2 from "../images/selling-on-privor.png";
import { ReactComponent as Market3 } from "../images/selling-on-storefront.svg";
import { ReactComponent as Market4 } from "../images/selling-on-marketplace.svg";

function Illustration({ img }) {
    if (img == `selling-on-social-media`) {
        return <Market1 className="w-full h-full" />;
    }

    if (img == `selling-on-privor`) {
        return <img src={Market2} alt="selling on privor" />;
    }

    if (img == `selling-on-storefront`) {
        return <Market3 className="w-full h-full" />;
    }

    if (img == `selling-on-marketplace`) {
        return <Market4 className="w-full h-full" />;
    }

    return null;
}

const Markets = (props) => (
    <section className="bg-secondary dark:bg-gray-800">
        <div className="w-full max-w-6xl mx-auto px-4 py-8 md:px-8 md:py-24">
            <h2 className="text-3xl mb-6 md:mb-10 text-center leading-tight font-display max-w-xl mx-auto">
                {props.title}
            </h2>

            <Tabs className="flex flex-col md:flex-row items-center w-full">
                <div className="w-full md:w-1/2 h-full mb-10 md:mb-0">
                    {props.content.map((item, i) => (
                        <TabPanel key={i} index={i}>
                            <Illustration img={item.img}></Illustration>
                        </TabPanel>
                    ))}
                </div>

                <TabList className="w-full md:w-1/2 flex flex-wrap md:pl-12">
                    {props.content.map((item, i) => (
                        <Tab
                            key={i}
                            index={i}
                            className="w-1/2 flex flex-col p-4 md:p-6 focus:outline-none text-left rounded-lg cursor-pointer"
                            selectedClassName="bg-white dark:bg-gray-700 text-gray-700 shadow-md"
                            disabledClassName="text-gray-200"
                        >
                            <img
                                src="../icons/myicons/checkmark-circle-1.svg"
                                alt=""
                                className="mb-3 w-8 h-8"
                            />
                            <h3 className="text-sm font-bold leading-normal uppercase tracking-widest mb-4 font-display dark:text-gray-200">
                                {item.title}
                            </h3>
                            <p className="text-sm md:text-base dark:text-gray-400">
                                {item.desc}
                            </p>
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
        </div>
    </section>
);

Illustration.propTypes = {
    img: PropTypes.string,
};

Markets.propTypes = {
    props: PropTypes.array,
    title: PropTypes.string,
    content: PropTypes.array,
};

export default Markets;
