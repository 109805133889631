import PropTypes from "prop-types";
import React from "react";

import parse from "html-react-parser";
import { Link } from "gatsby";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import Image from "../components/image";

function PlayVideo({ shop }) {
    return (
        <Link
            to={shop}
            state={{
                modal: true,
                noScroll: true,
            }}
        >
            <div className="flex items-center justify-center transition duration-150 ease-in-out transform hover:scale-150 cursor-pointer">
                <svg
                    width="80"
                    viewBox="0 0 174 174"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path d="M0 0h174v174H0z" />
                        <path
                            d="M153 87.5c0 36.178-29.322 65.5-65.5 65.5S22 123.678 22 87.5 51.322 22 87.5 22 153 51.322 153 87.5z"
                            fill="#FFF"
                            opacity=".9"
                        />
                        <path
                            d="M79.203 65.763l28.129 16.577c3.557 2.093 3.557 7.227 0 9.32l-28.13 16.577c-3.623 2.137-8.202-.47-8.202-4.664V70.427c0-4.195 4.58-6.801 8.203-4.664z"
                            fill="#15C"
                        />
                    </g>
                </svg>
            </div>
        </Link>
    );
}

const Testimonials = (props) => (
    <section className="bg-secondary dark:bg-gray-700">
        <div className="w-full max-w-6xl mx-auto px-4 py-8 md:px-8 md:py-24">
            <h2 className="text-3xl mb-6 md:mb-10 text-center leading-tight font-display max-w-xl mx-auto">
                {props.title}
            </h2>

            <CarouselProvider isIntrinsicHeight={true} totalSlides={3} step={1}>
                <Slider>
                    {props.content.map((item) => (
                        <Slide index={item} key={item}>
                            <div className="flex flex-col md:flex-row w-full">
                                <Image
                                    isBackground={true}
                                    filename={`stores/snap/${item.image}.webp`}
                                    className="w-full md:w-1/2 flex items-center justify-center"
                                    style={{
                                        height: `auto`,
                                        minHeight: `350px`,
                                        maxHeight: `400px`,
                                        backgroundSize: `cover`,
                                        borderRadius: `1rem`,
                                        overflow: `hidden`,
                                    }}
                                    content={<PlayVideo shop={item.shop} />}
                                />

                                <div className="w-full md:w-1/2 bg-white dark:bg-gray-600 p-6 md:p-10 h-auto rounded-lg z-10 md:-ml-8 -mt-8 md:mt-8 md:shadow-lg">
                                    <img
                                        src={`../images/stores/logo/${item.image}.png`}
                                        alt=""
                                        className="mb-8"
                                    />
                                    <p className="text-lg md:text-xl text-gray-700 dark:text-gray-200 leading-relaxed">
                                        {parse(item.text)}
                                    </p>
                                    <h5 className="mt-6 font-bold dark:text-gray-400">
                                        {item.shop}
                                    </h5>
                                    <p className="text-gray-500 dark:text-gray-200 text-sm">
                                        {item.person}
                                    </p>
                                </div>
                            </div>
                        </Slide>
                    ))}
                </Slider>
            </CarouselProvider>

            <p className="mt-8 text-center">
                <Link className="text-center" to="/seller-story">
                    Baca pengalaman mereka di Seller Story &rarr;
                </Link>
            </p>
        </div>
    </section>
);

PlayVideo.propTypes = {
    shop: PropTypes.string,
};

Testimonials.propTypes = {
    props: PropTypes.array,
    title: PropTypes.string,
    content: PropTypes.array,
};

export default Testimonials;
