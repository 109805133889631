import React, { useState, useEffect, useContext } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SEOAdditional from "../components/seo-additional";
import Button from "../components/button";
import WindowSize from "../components/window-size";

import Feature from "../components/feature";
import FeatureList from "../components/feature-list";
import Testimonials from "../components/testimonials";
import Markets from "../components/markets";
import Brands from "../components/brands";
import Cta from "../components/cta";

import parse from "html-react-parser";

import { Link, graphql } from "gatsby";
import PropTypes from "prop-types";

import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { ReactComponent as Hero1 } from "../images/hero-1.svg";
import { ReactComponent as Hero2 } from "../images/hero-2.svg";
import { ReactComponent as Home1 } from "../images/home-1.svg";
import { ReactComponent as Home2 } from "../images/home-2.svg";

import { ThemeContext } from "../context/themeContext";

import { UilAngleDown } from "@iconscout/react-unicons";

function Icon({ icon }) {
    let uil = {
        chevronDown: UilAngleDown,
    };

    const MyIcon = uil[icon];
    return <MyIcon size="24" color="#718096" />;
}

function convertDateTimeDBtoIndo(string) {
    if (string.length == 0) {
        return "";
    }
    let bulanIndo = [
        "",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];

    let date = string.split(" ")[0];

    let tanggal = date.split("-")[2];
    let bulan = date.split("-")[1];
    let tahun = date.split("-")[0];

    return `${tanggal} ${bulanIndo[Math.abs(bulan)]} ${tahun}`;
}

function Illustration({ img }) {
    if (img == `hero-1`) {
        return <Hero1 />;
    }

    if (img == `hero-2`) {
        return <Hero2 />;
    }

    if (img == `home-1.svg`) {
        return <Home1 />;
    }

    if (img == `home-2.svg`) {
        return <Home2 />;
    }

    return null;
}

function IndexPage({ data }) {
    const size = WindowSize();

    let d = data.contentfulHomepage;

    const hero = d.hero.filter(function (i) {
        return i.publish === true;
    });

    // Fetch latest news data
    const [latestNews, setLatestNews] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(1);

    useEffect(() => {
        fetch(`${process.env.GATSBY_NGORDER_APP_URL}/API/app_update?limit=3`)
            .then((response) => response.json())
            .then((resultData) => {
                setLatestNews(resultData.data);
            });
    }, []);

    // Fetch counter shop data
    const [brandsTitle, setBrandsTitle] = useState([]);

    useEffect(() => {
        fetch(`${process.env.GATSBY_NGORDER_APP_URL}/API/shops`)
            .then((response) => response.json())
            .then((resultData) => {
                let count = resultData.shops.toString();
                let countThousand = count.substr(0, count.length - 3) + "rb++";
                setBrandsTitle(`${countThousand} ${d.brands.title}`);
            });
    }, []);

    const { theme } = useContext(ThemeContext);

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />

            <section className="z-0 w-full h-auto bg-secondary dark:bg-gray-800 pt-10 pb-20">
                <CarouselProvider
                    isIntrinsicHeight={true}
                    totalSlides={hero.length}
                    isPlaying={true}
                    lockOnWindowScroll={true}
                    className="w-full max-w-6xl mx-auto px-4"
                >
                    <Slider className="bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-400 rounded-xl">
                        {hero.map((item, i) => (
                            <Slide index={item} key={`slider-${i}`}>
                                {item.isImage === false ? (
                                    <div
                                        id={`hero-banner-${i}`}
                                        className="flex text-left h-full px-10 py-10 items-center"
                                        style={
                                            theme !== "dark"
                                                ? {
                                                      backgroundColor:
                                                          item.background.color,
                                                  }
                                                : {}
                                        }
                                    >
                                        <div className="flex-1">
                                            <h1 className="text-2xl xs:text-3xl sm:text-4xl md:text-4xl leading-tight mb-6 font-display breakline dark:text-gray-200">
                                                {item.title}
                                            </h1>

                                            {size.width < 768 && (
                                                <div className="w-full h-auto mb-4">
                                                    <img
                                                        src={
                                                            item.illustrationUrl
                                                        }
                                                        alt={item.title}
                                                    />
                                                </div>
                                            )}

                                            <h2 className="text-lg sm:text-xl md:text-xl mb-8 max-w-lg text-gray-600 dark:text-gray-400">
                                                {item.subtitle}
                                            </h2>

                                            <div className="flex flex-wrap">
                                                {item.cta.map((item) => (
                                                    <Button
                                                        key={item.text}
                                                        text={item.text}
                                                        size="lg"
                                                        color={item.color}
                                                        url={item.url}
                                                        className="cta_banner mb-2"
                                                    />
                                                ))}
                                            </div>

                                            <p className="max-w-md text-sm mt-10 leading-normal font-normal">
                                                {item.note}
                                            </p>
                                        </div>

                                        {size.width >= 768 && (
                                            <div className="flex-1">
                                                {/* <Illustration
                                                    img={item.background.image}
                                                /> */}

                                                <img
                                                    src={item.illustrationUrl}
                                                    alt={item.title}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <a
                                        href={item.linkUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={
                                                size.width <= 768
                                                    ? item.imageMobileUrl
                                                    : item.imageUrl
                                            }
                                            className="object-cover h-full w-full"
                                        />
                                    </a>
                                )}
                            </Slide>
                        ))}
                    </Slider>
                    <div className="flex w-full mt-4">
                        <DotGroup className="mx-auto">
                            {hero.map((item, i) => (
                                <Dot
                                    key={item}
                                    className="w-2 h-2 rounded-full mx-2 bg-gray-700 dark:bg-gray-400 focus:outline-none opacity-25"
                                    slide={i}
                                ></Dot>
                            ))}
                        </DotGroup>
                    </div>
                </CarouselProvider>
            </section>

            <section className="px-4">
                <div className="z-10 relative left-0 right-0 mt-10 md:-mt-10 w-full max-w-xl h-32 md:h-36 mx-auto rounded-lg mb-20 shadow-lg">
                    <div className="absolute left-0 top-0 -mt-8 md:-mt-10 z-20 w-20 md:w-28">
                        <img
                            className="w-32"
                            src={
                                theme === "dark"
                                    ? "../images/whats-new-light.svg"
                                    : "../images/whats-new.svg"
                            }
                        />
                    </div>

                    <CarouselProvider
                        isIntrinsicHeight={true}
                        totalSlides={3}
                        step={1}
                    >
                        <Slider>
                            {latestNews
                                .filter(function (i) {
                                    return i.title !== null;
                                })
                                .map((item, i) => (
                                    <Slide index={item} key={`slider-${i}`}>
                                        <Link
                                            to={`../update-detail?id=${item.id}`}
                                            state={{
                                                modal: true,
                                                noScroll: true,
                                            }}
                                            className=""
                                        >
                                            <div className="flex flex-col">
                                                <div className="h-32 md:h-36 flex flex-row items-center rounded-lg bg-gray-50 dark:bg-gray-700">
                                                    <img
                                                        src={`${item.img}?width=auto&height=300`}
                                                        alt=""
                                                        className="w-32 h-32 md:w-2/5 md:h-full rounded-lg md:rounded-r-none object-cover"
                                                    />

                                                    <div className="px-6">
                                                        <h4
                                                            className={`font-medium leading-tight text-sm sm:text-base`}
                                                        >
                                                            {item.title.length <
                                                            120
                                                                ? item.title
                                                                : item.title.substring(
                                                                      0,
                                                                      120
                                                                  ) + "..."}
                                                        </h4>
                                                        <p className="text-gray-500 text-xs mt-1">
                                                            {convertDateTimeDBtoIndo(
                                                                item.created_at
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </Slide>
                                ))}
                        </Slider>
                    </CarouselProvider>
                </div>
            </section>

            {d.features.slice(0, 3).map((item) => (
                <Feature
                    contentPosition={item.contentPosition}
                    key={item.title}
                    svgImg={<Illustration img={item.svgImg}></Illustration>}
                    img={item.img}
                    label={item.label}
                    title={item.title}
                    description={parse(item.description)}
                    moreButton={true}
                    moreButtonUrl={item.more}
                />
            ))}

            <section className="max-w-6xl mx-auto py-10">
                <div className="flex flex-wrap w-full">
                    {d.features.slice(-3).map((item) => (
                        <FeatureList
                            key={item.title}
                            props={item}
                            bgBox={false}
                            moreButton={true}
                            moreButtonUrl={item.more}
                            imgWidth="4rem"
                            imgHeight="4rem"
                        />
                    ))}
                </div>
            </section>

            <Markets title={d.markets.title} content={d.markets.content} />

            <Testimonials
                title={data.contentfulTestimonials.title}
                content={data.contentfulTestimonials.content}
            />

            <Brands title={brandsTitle} content={[...Array(7).keys()]} />

            <Cta />

            {d.seoAdditional.length ? (
                <section className="px-4 bg-gray-50 dark:bg-gray-800 space-y-5">
                    {d.seoAdditional.map((seoAdd, key) => (
                        <SEOAdditional
                            key={`seo-additional-${key}`}
                            titleTag={seoAdd.titleTag}
                            title={seoAdd.title}
                            content={seoAdd.content}
                            className={key >= itemsToShow ? "hidden" : ""}
                        />
                    ))}

                    {itemsToShow == 1 ? (
                        <div className="w-full max-w-6xl mx-auto">
                            <button
                                type="button"
                                className="flex flex-row text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 hover:underline border-b border-dashed"
                                onClick={() =>
                                    setItemsToShow(d.seoAdditional.length)
                                }
                            >
                                <span>Selengkapnya</span>
                                <Icon icon={"chevronDown"} />
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </section>
            ) : (
                ""
            )}
        </Layout>
    );
}

Icon.propTypes = {
    icon: PropTypes.string,
};

Illustration.propTypes = {
    img: PropTypes.string,
};

IndexPage.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulHomepage {
            title
            seo {
                title
                description
                keywords
            }
            seoAdditional {
                titleTag
                title
                content
            }
            hero {
                publish
                isImage
                imageUrl
                imageMobileUrl
                linkUrl
                illustrationUrl
                title
                subtitle
                note
                cta {
                    color
                    text
                    url
                }
                background {
                    color
                    image
                    position
                }
            }
            features {
                title
                label
                img
                svgImg
                contentPosition
                description
                more
            }
            markets {
                title
                content {
                    img
                    title
                    desc
                }
            }
            brands {
                title
            }
        }
        contentfulTestimonials {
            title
            content {
                shop
                person
                image
                text
                videoUrl
            }
        }
    }
`;

export default IndexPage;
